import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalVariables } from 'global-variables';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LoginService {

    private url = GlobalVariables.appURL;

    constructor(private _httpClient: HttpClient) { }

    public login(data: any): any {
        return this._httpClient.post(this.url + 'customer-users/login', data);
    }

    public forgetPassword(data: any): any {
        return this._httpClient.post(this.url + 'customer-users/forgot-password', data);
    }

    public resetPassword(data: any): any {
        return this._httpClient.post(this.url + 'customer-users/reset-password', data);
    }

    public register(data: any): any {
        return this._httpClient.post(this.url + 'customer-users/signup', data);
    }

    public verify_user(token: string): Observable<Object> {
        return this._httpClient.post(this.url + '/users/verify/', {token});
      }
}

