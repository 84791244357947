import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, Subject } from "rxjs";

@Injectable({ providedIn: "root" })
export class ResourceService {
    languageSubject = new Subject<any>();
    constructor(private httpClient: HttpClient) { }

    loadResoruce(): Observable<any> {
        var language = localStorage.getItem("currentLanguage")
        ? localStorage.getItem("currentLanguage")
        : "english";

        return this.httpClient.get(`assets/languages/${language}.json`);
    }
}

// export class ResourceFilePath {
//     private static language = localStorage.getItem("currentLanguage")
//         ? localStorage.getItem("currentLanguage")
//         : "english";

//     public static login = `assets/languages/${ResourceFilePath.language}/common/component/login.json`;
//     public static sitedetails = `assets/languages/${ResourceFilePath.language}/admin/usermgmt/site.json`;
//     public static companydetail = `assets/languages/${ResourceFilePath.language}/admin/usermgmt/company.json`;
// }