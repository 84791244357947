<!-- <div class="page-title-area" *ngFor="let Content of pageTitle;" style="background-image: url({{Content.bgImage}});">
    <div class="container">
        <h1>{{Content.title}}</h1>
    </div>
</div> -->

<div class="profile-authentication-area ptb-30">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 offset-lg-3 col-md-12">
                <div class="login-form">
                    <div class="row">
                        <div class="col-lg-6 col-md-12 col-sm-12" style="margin-bottom: 25px;">
                            <h2 style="margin: 0;">{{resource.signIn}}</h2>
                            <!-- <div style="font-weight: bold; display: contents;" class="col-lg-6 col-md-12 col-sm-12" 
                                [ngStyle]="{'color': tokenValid ? 'black' : 'red' }">
                                {{message}}
                            </div> -->
                        </div>
                        <div style="text-align: right;" class="col-lg-6 col-md-12 col-sm-12">
                            {{resource.create_account}} ? <a style="font-weight: bold;"
                                routerLink="/register">{{resource.signUp}}</a>
                        </div>
                    </div>
                    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" autocomplete="off">
                        <div class="form-group">
                            <!-- <label>{{resource.email}}</label> -->
                            <mat-form-field>
                                <mat-label> {{resource.email}}</mat-label>
                                <input [placeholder]="resource.email" matInput formControlName="email" [readonly]="!tokenValid"
                                    oninput="this.value = this.value.toLowerCase()" type="email" maxlength="100">
                                <mat-error *ngIf="submittedLogin && f.email.errors?.required" class="invalid-feedback">
                                    {{resource.email}} {{resource.is_required}}
                                </mat-error>
                                <mat-error
                                    *ngIf="submittedLogin  && f.email.errors?.pattern && (loginForm.touched || loginForm.dirty)"
                                    class="invalid-feedback">
                                    {{resource.invalid}} {{resource.email}}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="form-group">
                            <!-- <label>{{resource.password}}</label> -->
                            <mat-form-field>
                                <mat-label> {{resource.password}}</mat-label>
                                <input [placeholder]="resource.password" matInput formControlName="password" [readonly]="!tokenValid"
                                    type="password" maxlength="20">
                                <mat-error *ngIf="submittedLogin && f.password.errors?.required"
                                    class="invalid-feedback">
                                    {{resource.password}} {{resource.is_required}}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="row align-items-center">
                            <div class="col-lg-6 col-md-6 col-sm-6 remember-me-wrap">
                                <p>
                                    <input type="checkbox" id="rememberMe" formControlName="rememberMe">
                                    <label for="rememberMe">{{resource.remember_me}}</label>
                                </p>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 lost-your-password-wrap">
                                <a routerLink="/forgot-password"
                                    class="lost-your-password">{{resource.forgot_password}}?</a>
                            </div>
                        </div>
                        <button type="submit">{{resource.signIn}}</button>
                        <!-- <hr>
                        <p class="text-center">or Login with</p>
                        <div class="article-footer">
                            <div class="article-share">
                                <ul class="social">
                                    <li><img src="assets/img/google_icon.png"></li>
                                    <li><img src="assets/img/fb_icon.png"></li>
                                </ul>
                            </div>
                        </div> -->
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>