import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalVariables } from 'global-variables';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  private url = GlobalVariables.appURL;

  constructor(private _httpClient: HttpClient) { }

  public getCountryList(): Observable<any> {
    return this._httpClient.get(this.url + 'countries');
  }

  public getStatesList(countryId: string): Observable<any> {
    const params = new HttpParams().set('filter', JSON.stringify({ fields: { name: true, id: true } }));
    return this._httpClient.get(this.url + 'states/country/' + countryId, { params });
  }

  public getCityList(stateId: string): Observable<any> {
    return this._httpClient.get(this.url + 'cities/state/' + stateId);
  }

  public getCurrencyList(): Observable<any> {
    return this._httpClient.get(this.url + 'currencies');
  }

  public getConstantList(constantType: any): Observable<any> {
    const params = new HttpParams().set('filter', JSON.stringify({ fields: { name: true, id: true, code: true } }));
    return this._httpClient.get(this.url + 'constants/constantType/' + constantType, { params });
  }

  public getCategory(): Observable<any> {
    return this._httpClient.get(this.url + 'category');
  }

  public getTimeZoneList(): Observable<any> {
    return this._httpClient.get(this.url + 'timezones');
  }

  public getConstants(filter: any): Observable<any> {
    return this._httpClient.get(this.url + 'constants/', {
      params: new HttpParams().set('filter', JSON.stringify(filter))
    });
  }

  public getClientLocation(): Observable<any> {
    return this._httpClient.get('https://geolocation-db.com/json/');
  }

  getAddress(latitude: number, longitude: number): Observable<any> {
    const apiUrl = `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${latitude}&lon=${longitude}`;
    return this._httpClient.get(apiUrl);
  }

  public getCurrentLocation(data: any): Observable<any> {
    return this._httpClient.post(this.url + 'user-location', data);
  }
}
