<div class="coming-soon-area" style="background-color:#0e0129;">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="coming-soon-content">
                    <h2 class="text-center">{{resource.games_page_heading}}</h2>
                    <h5 class="text-center"><i>{{resource.games_heading}}</i></h5>
                    <p class="text-center">{{resource.games_detail}}</p>
                    <div class="row col-sm-6 col-lg-6 offset-md-3">
                        <div id="timer" class="flex-wrap d-flex text-center">
                            <div id="days" class="align-items-center flex-column d-flex justify-content-center">{{days}}
                                <span>{{resource.days}}</span>
                            </div>
                            <div id="hours" class="align-items-center flex-column d-flex justify-content-center">
                                {{hours}}
                                <span>{{resource.hours}}</span>
                            </div>
                            <div id="minutes" class="align-items-center flex-column d-flex justify-content-center">
                                {{minutes}} <span>{{resource.minutes}}</span></div>
                            <div id="seconds" class="align-items-center flex-column d-flex justify-content-center">
                                {{seconds}} <span>{{resource.seconds}}</span></div>
                        </div>
                    </div>
                    <!-- <form class="newsletter-form">
                        <div class="form-group">
                            <input type="email" class="input-newsletter" placeholder="Enter your email" name="EMAIL" required autocomplete="off">
                        </div>
                        <button type="submit" class="default-btn">Subscribe</button>
                    </form> -->
                </div>
            </div>
        </div>
    </div>
</div>