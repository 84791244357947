
import { NotifierOptions } from "angular-notifier";

export class Config {
    public static notifierDefaultOptions: NotifierOptions = {
        position: {
            horizontal: {
                position: 'middle',
                distance: 12,
            },
            vertical: {
                position: 'bottom',
                distance: 12,
                gap: 10,
            },
        },
        theme: 'material',
        behaviour: {
            autoHide: 2000,
            onClick: false,
            onMouseover: 'pauseAutoHide',
            showDismissButton: true,
            stacking: 4,
        },
        animations: {
            enabled: true,
            show: {
                preset: 'slide',
                speed: 300,
                easing: 'ease',
            },
            hide: {
                preset: 'fade',
                speed: 300,
                easing: 'ease',
                offset: 50,
            },
            shift: {
                speed: 300,
                easing: 'ease',
            },
            overlap: 150,
        },
    };


}
