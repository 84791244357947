import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UsersInfo } from '../interfaces/users-info';
import { StorageKey, StorageService } from './common/storage.service';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UserInfoService {
  uinfo: UsersInfo;
  constructor(private _router: Router,
    private _storageService: StorageService
  ) {
    this.uinfo = {
      loginUserInfo: null,
      authToken: null,
      loginFlag: false
    }
  }

  setUserInfo(usr: any) {
    this.uinfo.loginUserInfo = usr;
  }

  setToken(token: any = null) {
    this.uinfo.authToken = token;
    if (!!token) {
      sessionStorage.setItem('currentUser', JSON.stringify({ token: token, userInfo: this.uinfo.loginUserInfo }));
    }
  }

  setLoginFlag(flag: boolean = false) {
    this.uinfo.loginFlag = flag;
  }

  getUserInfo() {
    // return this.uinfo.loginUserInfo;
    let currentSession = sessionStorage.getItem('currentUser');
    if (currentSession) {
      {
        let data = JSON.parse(currentSession);
        return data.userInfo;

      }
    } else {
      return null;
    }
  }

  getAccessToken() {
    var token = this._storageService.getValue(StorageKey.authToken);
    return token ? token : null;
    // return this.uinfo.authToken;  
  }

  getLoginFlag() {
    if (!this.uinfo.loginFlag) {
      let currentSession = sessionStorage.getItem('currentUser');
      if (currentSession) {
        return true;
      } else {
        return false;
      }
    } else {
      return this.uinfo.loginFlag;
    }
  }

  logout() {
    this.setLoginFlag(false);
    this.setToken();
    this._storageService.removeValue(StorageKey.authToken);
    this._storageService.removeValue(StorageKey.currentUser);
    sessionStorage.removeItem('currentUser');
    //this.cookieService.delete('currentUser');
    this._router.navigate(['/']);
  }
}
