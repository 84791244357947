import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalVariables } from '../../../global-variables';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class WishListService {
    private url = GlobalVariables.appURL;

    constructor(private _httpClient: HttpClient) { }


    public add(data: any): Observable<any> {
        return this._httpClient.post(this.url + 'campaignwishlist', data);
    }

    public deleteById(data: any): Observable<any> {
        return this._httpClient.delete(this.url + 'campaignwishlist/' + data.id);
    }

    public list(data: any): Observable<any> {
        return this._httpClient.post(this.url + 'campaignwishlist/list', data);
    }
}

