<div class="why-choose-area pt-4 pb-3">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-12 col-sm-12 col-md-12">
                <div class="text-center">
                    <!-- <i class='bx bx-money'></i> -->
                    <h3>{{resource.footer_heading1}}</h3>
                    <!-- <span><i><b>Oh!</b> By the way, have fun playing games for Free</i></span> -->
                </div>
            </div>
            <!-- <div class="col-lg-6 col-sm-6 col-md-6 pb-3">
                <div class="single-why-choose-box" *ngIf="showInstallBox" (click)="onClickInstall()">
                    <i class='bx bx-archive'></i>
                    <span>{{resource.footer_install_heading1}}</span>
                </div>
            </div> -->
        </div>
        <div class="why-choose-content">
            <h3>{{resource.footer_heading2}}</h3>
            <div *ngIf="!isLoggedIn"><a routerLink="/login" class="default-btn">{{resource.signIn}}</a></div>
            <div *ngIf="!isLoggedIn">{{resource.new_customer}}? <a routerLink="/register">{{resource.signUp}}</a>.
            </div>
        </div>
        <div class="text-center col-sm-7 offset-md-3" style="display :inline-flex;">
            <div><img src="assets/img/quick-tips.png" /></div>
            <div style="margin: auto 0px;">
                {{resource.footer_quicktip_heading}} <b><a routerLink="quick-tips">{{resource.click_here}}</a></b></div>
        </div>
    </div>
</div>
<footer class="footer-area">
    <!-- <div class="container">
        <div class="row">
            <div class="col-lg-12 col-sm-12 col-md-12">
                <div class="single-footer-widget" *ngFor="let Content of singleFooterWidget;">
                    <a routerLink="/" class="logo d-inline-block">
                        <img [src]="Content.logo" alt="image">
                    </a>
                    <p>Claim savings instantly or add to wish list for a friendly reminder. </p>
                    <p><b>Oh!</b> By the way, have fun playing games for Free</p>
                    <p>For easy access Click <i class='bx bx-archive px-1'></i> <span
                            (click)="onClickInstall()">‘Install’</span> to save right on your
                        mobile screen
                    </p>
                    <p>{{Content.paragraph}}</p>
                    <ul class="social-links">
                        <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                    </ul> 
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-footer-widget pl-4">
                   <h3>Useful Links</h3>
                    <ul class="links-list">
                        <li><a routerLink="/privacy-policy">Privacy Policy</a></li>
                        <li><a routerLink="/customer-service">Return Policy</a></li>
                        <li><a routerLink="/term-condition">Terms & Conditions</a></li>
                        <li><a routerLink="/how-it-works">How It Works?</a></li>
                        <li><a routerLink="/faq">FAQs</a></li>
                    </ul> 
                </div>
            </div> 

            <div class="col-lg-3 col-sm-6 col-md-6">
                <div class="single-footer-widget">
                    <h3>Popular Store</h3>
                    <ul class="links-list">
                        <li><a routerLink="/stores">Excavator</a></li>
                        <li><a routerLink="/stores">The Bird</a></li>
                        <li><a routerLink="/stores">Triple One</a></li>
                        <li><a routerLink="/stores">Hi-Tech</a></li>
                    </ul>
                </div>
            </div> 

            <div class="col-lg-4 col-sm-4 col-md-4">
                <div class="single-footer-widget">
                    <h3>Contact Info</h3>
                    <ul class="footer-contact-info">
                        <li>Address: 3600 Steeles Avenue East Markham, ON, L3R 9Z7, Canada</li>
                        <li>Email: <a href="mailto:hello@scanfluence.com">hello@scanfluence.com</a></li>
                        <li>Phone: <a href="tel:+44587154756">+44 587 154756</a></li>
                        <li>Fax: <a href="tel:+55587154756">+55 587 154756</a></li>
                    </ul>
                </div>
            </div> 
        </div>
    </div> -->

    <div class="copyright-area">
        <div class="container">
            <div class="row">
                <div class="col-lg-7 col-md-7 col-sm-7">
                    <ul>
                        <li><a routerLink="/privacy-policy">{{resource.privacy_policy}}</a></li>
                        <li><a routerLink="/term-condition">{{resource.term_conditions}}</a></li>
                        <li><a routerLink="/contact">{{resource.contact_us}}</a></li>
                    </ul>
                </div>
                <div class="col-lg-5 col-md-5 col-sm-5">
                    <p>{{resource.copy_right}} 2024 - {{resource.powered_by}} <strong>Scanfluence Software Inc.</strong>
                    </p>
                    <!-- <p>Copyright @{{ marxaYear }} <strong>Scanfluence</strong> is Proudly Powered by <a href="#"
                            target="_blank">Scanfluence</a></p> -->
                </div>
            </div>
        </div>
    </div>
</footer>