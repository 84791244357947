<div class="profile-authentication-area ptb-30">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 offset-lg-3 col-md-12">
        <div class="login-form">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
              <h2>Reset Password</h2>
            </div>
          </div>
          <form [formGroup]="resetForm" (ngSubmit)="onSubmit()" autocomplete="off">
            <div class="form-group">
              <label>Password</label>
              <mat-form-field>
                <input matInput formControlName="password" type="password" maxlength="20">
                <mat-error *ngIf="submitted && f.password.errors?.required" class="invalid-feedback">
                  Password is required
                </mat-error>
              </mat-form-field>
            </div>
            <div class="form-group">
              <label>Confirm Password</label>
              <mat-form-field>
                <input matInput formControlName="confirmPassword" type="password" maxlength="20">
                <mat-error *ngIf="submitted && f.confirmPassword.errors?.required" class="invalid-feedback">
                  Confirm Password is required
                </mat-error>
                <mat-error class="invalid-feedback"
                  *ngIf="(f.confirmPassword.errors?.mustMatch && (resetForm.touched || resetForm.dirty))">
                  Password and confirm password doesn't match.
                </mat-error>
              </mat-form-field>
            </div>
            <button type="submit">Submit</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>