import { Injectable } from '@angular/core';
import { UtilityService } from '../utility.service';

@Injectable({
    providedIn: 'root'
})
export class IndexedDBService {
    countryList: any[] = [];

    constructor(private utilityService: UtilityService) { }

    async getCountries(): Promise<any> {
        if(this.countryList.length > 0) {
            return this.countryList;
        }
        if (window.indexedDB) {
            this.countryList = await this.checkIndexedDb();
            if (this.countryList.length == 0) {
                await this.setupIndexedDb();
            }
        } else {
            await this.getCountryList().toPromise().then(data => this.setCountryList(data)).catch(err => {});
        }
        return this.countryList;
    }

    private async checkIndexedDb(): Promise<any[]> {
        return new Promise((resolve, reject) => {
            var request = window.indexedDB.open('REGION_DB', 1);
            request.onsuccess = (event: any) => {
                var db = event.target.result;
                if (!db.objectStoreNames.contains("countries")) {
                    var req = window.indexedDB.deleteDatabase('REGION_DB');
                    req.onsuccess = () => {
                        resolve([]);
                    };
                } else {
                    var transaction = db.transaction(['countries'], 'readwrite');
                    transaction.onsuccess = (event: any) => {
                        resolve([]);
                    };
                    transaction.oncomplete = (event: any) => {
                        resolve([]);
                    };
                    transaction.onerror = (event: any) => {
                        resolve([]);
                    };
                    var countryStore = transaction.objectStore('countries');
                    countryStore.getAll().onsuccess = ((event: any) => {
                        resolve(event.target.result);
                    })
                }
            };
            request.onupgradeneeded = (event: any) => {
                resolve([]);
            }
            request.onerror = (event) => {
                resolve([]);
            };
        })
    }

    private async setupIndexedDb() {
        await this.getCountryList().toPromise().then((response: any) => {
            if (response.success) {
                this.countryList = response.data;
                var request = window.indexedDB.open('REGION_DB', 1);
                request.onsuccess = (event: any) => {
                    var db = event.target.result;
                    var transaction = db.transaction(['countries'], 'readwrite');
                    transaction.onsuccess = (event: any) => {
                    };
                    transaction.oncomplete = (event: any) => {
                    };
                    transaction.onerror = (event: any) => {
                    };
                    var countryStore = transaction.objectStore('countries');
                    this.countryList.forEach((country: any) => {
                        var db_op_req = countryStore.add(country, country.id); // IDBRequest
                        db_op_req.onsuccess = (event: any) => {
                        }
                    });
                };
                request.onupgradeneeded = (event: any) => {
                    var db = event.target.result;
                    if (!db.objectStoreNames.contains("countries")) {
                        const countryStore = db.createObjectStore("countries", { keypath: "id" });
                        countryStore.createIndex("id", "id", { unique: true });
                    }
                }
                request.onerror = (event) => {
                };
            }
        }).catch(err => {});
    }

    private getCountryList() {
        return this.utilityService.getCountryList()
    }

    private setCountryList(response: any) {
        if (response.success) {
            this.countryList = response.data;
        }
    }
}