import { Component, OnInit } from '@angular/core';
import { ResourceService } from 'src/app/services/common/resource.service';

@Component({
  selector: 'app-quick-tip-page',
  templateUrl: './quick-tip-page.component.html',
  styleUrls: ['./quick-tip-page.component.scss']
})
export class QuickTipPageComponent implements OnInit {

  resource: any = {};
  constructor(private resourceService: ResourceService) { }

  ngOnInit(): void {
    this.loadResoruce();
    this.resourceService.languageSubject.subscribe(response => {
      this.resource = response;
    });
  }

  loadResoruce() {
    this.resourceService.loadResoruce().subscribe(
      response => {
        this.resource = response;
      });
  }
}
