import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { Router } from "@angular/router";
import { StorageKey, StorageService } from "src/app/services/common/storage.service";
import { UserInfoService } from "src/app/services/user-info.service";


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(
        private router: Router,
        private userInfoService: UserInfoService,
        private storageService: StorageService
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        var authToken = this.userInfoService.getAccessToken();

        if (authToken) {
            const clonedReq = req.clone({
                headers: new HttpHeaders({
                    // 'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + authToken,
                })
            });
            return next.handle(clonedReq).pipe(
                tap(
                    succ => { },
                    err => {
                        if (err.status == 401) {
                            this.backToLogin();
                        }
                    }
                ));
        }
        else {
            return next.handle(req.clone());
            //   return next.handle(req.clone()).pipe(
            //     tap(
            //       succ => { },
            //       err => {
            //         if (err.status == 401) {
            //           this.backToLogin();
            //         }
            //       }
            //     ));
        }
    }

    private backToLogin() {
        this.userInfoService.logout();
    }
}



