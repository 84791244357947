import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TopHeaderComponent } from './top-header/top-header.component';
import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './app-navbar/navbar.component';
import { StickyNavModule } from 'ng2-sticky-nav';
import { ClipboardModule } from 'ngx-clipboard';
import { TooltipModule } from 'ng2-tooltip-directive';
import { FormsModule } from '@angular/forms';
import { NgxScrollTopModule } from 'ngx-scrolltop';
import { TabsModule } from 'ngx-tabset';
import { LightboxModule } from 'ngx-lightbox';
import { MaterialModule } from '../core/material.module';
import { LoaderComponent } from './loader/loader.component';


@NgModule({
  declarations: [TopHeaderComponent, NavbarComponent, FooterComponent, LoaderComponent],
  imports: [
    CommonModule,
    ClipboardModule,
    TooltipModule,
    FormsModule,
    NgxScrollTopModule,
    TabsModule,
    StickyNavModule,
    LightboxModule,
    RouterModule,
    MaterialModule
  ],
  exports: [TopHeaderComponent, NavbarComponent, FooterComponent, LoaderComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ]
})
export class LayoutModule { }
