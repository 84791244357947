import { Component, OnInit } from '@angular/core';
import { Router, NavigationCancel, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { filter } from 'rxjs/operators';
import { StorageKey, StorageService } from './services/common/storage.service';
import { LoginService } from './services/login.service';
import { CookieService } from 'ngx-cookie-service';
import { UserInfoService } from './services/user-info.service';
declare let $: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class AppComponent {
    location: any;
    routerSubscription: any;
    popup:boolean= false;

    constructor(private router: Router,
        private cookieService: CookieService,
        private loginService: LoginService,
        private storageService: StorageService,
        private userInfoService: UserInfoService,
        private activatedRoute: ActivatedRoute,
    ) {
    }

    ngOnInit() {
        this.storageService.setValue(StorageKey.currentLanguage, 'english');
        this.recallJsFuntions();
        //this.checkCookie();
        this.activatedRoute.queryParams.subscribe(params => {
            this.popup = params.popup
        });
    }

    recallJsFuntions() {
        this.routerSubscription = this.router.events
            .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
            .subscribe(event => {
                this.location = this.router.url;
                if (!(event instanceof NavigationEnd)) {
                    return;
                }
                window.scrollTo(0, 0);
            });
    }

    checkCookie() {
        const currentUser = this.cookieService.get('currentUser');
        if (currentUser) {
            const { email, password } = JSON.parse(currentUser);
            var logininfo: any = {
                "email": email,
                "password": password,
            }

            this.loginService.login(logininfo).subscribe((res: any) => {
                if (res.success) {
                    let loginResponse = res.data;
                    if (loginResponse.token) {
                        let userData = loginResponse.userInfo;

                        this.storageService.setValue(StorageKey.currentUser, JSON.stringify(userData));
                        this.storageService.setValue(StorageKey.authToken, loginResponse.token);
                        this.userInfoService.setUserInfo(userData);
                        this.userInfoService.setLoginFlag(true);
                        this.userInfoService.setToken(loginResponse.token);
                        this.storageService.loginSubject.next(true);
                    } else {
                        this.storageService.loginSubject.next(false);
                    }
                    // Set a cookie with the user credentials if the "Remember Me" checkbox is checked
                    this.cookieService.set('currentUser', JSON.stringify(logininfo), 7);
                }
                else {
                    this.storageService.loginSubject.next(false);
                }
            },
                (err: any) => {
                    this.storageService.loginSubject.next(false);
                }
            )
        }
        else {
            var authToken = this.userInfoService.getAccessToken();
            if (authToken) {
            }
            else {
                this.userInfoService.setToken();
                this.storageService.removeValue(StorageKey.authToken);
                this.storageService.removeValue(StorageKey.currentUser);
                //sessionStorage.setItem('currentUser', JSON.stringify({}));
                sessionStorage.removeItem('currentUser');
                this.userInfoService.setLoginFlag(false);
                this.storageService.loginSubject.next(false);
                //this.cookieService.delete('currentUser');
                // this.router.navigate(['/']);

            }
        }
    }
}