import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ClipboardModule } from 'ngx-clipboard';
import { TooltipModule } from 'ng2-tooltip-directive';
import { NgxScrollTopModule } from 'ngx-scrolltop';
import { TabsModule } from 'ngx-tabset';
import { StickyNavModule } from 'ng2-sticky-nav';
import { AccordionModule } from 'ngx-accordion';
import { NgxPaginationModule } from 'ngx-pagination';
import { LightboxModule } from 'ngx-lightbox';
import { JoinNowComponent } from './common/join-now/join-now.component';
import { WhyChooseUsComponent } from './common/why-choose-us/why-choose-us.component';
import { BlogComponent } from './common/blog/blog.component';
import { FeaturedDealsComponent } from './common/featured-deals/featured-deals.component';
import { DealsComponent } from './common/deals/deals.component';
import { PopularStoreComponent } from './common/popular-store/popular-store.component';
import { PopularDealsComponent } from './common/popular-deals/popular-deals.component';
import { PartnerStyleOneComponent } from './common/partner-style-one/partner-style-one.component';
import { FeaturedDiscountDealsComponent } from './common/featured-discount-deals/featured-discount-deals.component';
import { TodaysHotDealsComponent } from './common/todays-hot-deals/todays-hot-deals.component';
import { NotFoundComponent } from './common/not-found/not-found.component';
import { BestDealsComponent } from './common/best-deals/best-deals.component';
import { DealsWithTabsComponent } from './common/deals-with-tabs/deals-with-tabs.component';
import { PartnerStyleTwoComponent } from './common/partner-style-two/partner-style-two.component';
import { CategoriesWithDealsComponent } from './common/categories-with-deals/categories-with-deals.component';
import { PropertyTypeDealsComponent } from './common/property-type-deals/property-type-deals.component';
import { RouterModule } from '@angular/router';
import { MaterialModule } from 'src/app/core/material.module';
import { ShareCampaignComponent } from './common/share-campaign/share-campaign.component';
import { CouponGenerateComponent } from './common/coupon-generate/coupon-generate.component';
import { OnlyNumberDirective } from './directives/onlyNumber.directive';
import { DailogComponent } from './common/dailog/dailog.component';
import { NgxBarcodeModule } from 'ngx-barcode';
import { PipesModule } from 'src/app/pipes/pipes.module';

@NgModule({
  declarations: [
    JoinNowComponent,
    WhyChooseUsComponent,
    BlogComponent,
    FeaturedDealsComponent,
    DealsComponent,
    PopularStoreComponent,
    PopularDealsComponent,
    PartnerStyleOneComponent,
    FeaturedDiscountDealsComponent,
    TodaysHotDealsComponent,
    NotFoundComponent,
    BestDealsComponent,
    DealsWithTabsComponent,
    PartnerStyleTwoComponent,
    CategoriesWithDealsComponent,
    PropertyTypeDealsComponent,
    ShareCampaignComponent,
    CouponGenerateComponent,
    OnlyNumberDirective,
    DailogComponent
  ],
  imports: [
    CommonModule,
    CarouselModule,
    ClipboardModule,
    TooltipModule,
    FormsModule,
    NgxScrollTopModule,
    TabsModule,
    StickyNavModule,
    AccordionModule,
    NgxPaginationModule,
    LightboxModule,
    ReactiveFormsModule,
    RouterModule,
    MaterialModule,
    NgxBarcodeModule,
    PipesModule,
  ],
  exports: [
    FormsModule,
    JoinNowComponent,
    WhyChooseUsComponent,
    BlogComponent,
    FeaturedDealsComponent,
    DealsComponent,
    PopularStoreComponent,
    PopularDealsComponent,
    PartnerStyleOneComponent,
    FeaturedDiscountDealsComponent,
    TodaysHotDealsComponent,
    NotFoundComponent,
    BestDealsComponent,
    DealsWithTabsComponent,
    PartnerStyleTwoComponent,
    CategoriesWithDealsComponent,
    PropertyTypeDealsComponent,
    OnlyNumberDirective,
    DailogComponent,
    PipesModule,
  ],
  entryComponents: [],
  providers: [DatePipe],
})
export class SharedModule { }
