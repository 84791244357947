import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotFoundComponent } from './components/shared/common/not-found/not-found.component';
import { ComingSoonPageComponent } from './components/pages/coming-soon-page/coming-soon-page.component';
import { FaqPageComponent } from './components/pages/faq-page/faq-page.component';
import { ProfileAuthenticationPageComponent } from './components/pages/profile-authentication-page/profile-authentication-page.component';
import { AuthGuard } from './core/Auth/auth.guard';
import { RegisterComponent } from './components/pages/register/register.component';
import { TermConditionPageComponent } from './components/pages/term-condition-page/term-condition-page.component';
import { PrivacyPolicyPageComponent } from './components/pages/privacy-policy-page/privacy-policy-page.component';
import { ForgetPasswordComponent } from './components/pages/forget-password/forget-password.component';
import { ResetPasswordComponent } from './components/pages/reset-password/reset-password.component';
import { QuickTipPageComponent } from './components/pages/quick-tip-page/quick-tip-page.component';

const routes: Routes = [
   
    { path: 'login', component: ProfileAuthenticationPageComponent },
    { path: 'forgot-password', component: ForgetPasswordComponent },
    { path: 'reset-password', component: ResetPasswordComponent },
    { path: 'register', component: RegisterComponent },
    { path: 'faq', component: FaqPageComponent },
    { path: 'deal', loadChildren: () => import('./components/deal/deal.module').then(m => m.DealModule) },
    { path: 'games', loadChildren: () => import('./components/games/games.module').then(m => m.GamesModule) },
    { path: 'contact', loadChildren: () => import('./components/contact/contact.module').then(m => m.ContactModule) },
    { path: 'stores', loadChildren: () => import('./components/store/store.module').then(m => m.StoreModule) },
    { path: 'term-condition', component: TermConditionPageComponent },
    { path: 'privacy-policy', component: PrivacyPolicyPageComponent },
    {
        path: 'my-dashboard'
        , canActivateChild: [AuthGuard]
        , loadChildren: () => import('./components/my-dashboard/my-dashboard.module').then(m => m.MyDashboardModule)
    },
    { path: 'coming-soon', component: ComingSoonPageComponent },
    { path: 'quick-tips', component: QuickTipPageComponent },
    { path: '', loadChildren: () => import('./components/home/home.module').then(m => m.HomeModule) },
    { path: '**', component: NotFoundComponent } // This line will remain down from the whole pages component list
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule { }