<!-- <div class="page-title-area" *ngFor="let Content of pageTitle;" style="background-image: url({{Content.bgImage}});">
    <div class="container">
        <h1>{{Content.title}}</h1>
    </div>
</div> -->

<div class="profile-authentication-area ptb-30">
  <div class="container">
    <div class="row">
      <div class="col-lg-8 offset-lg-2 col-md-12">
        <div class="login-form">
          <div class="row">
            <div class="col-lg-6 col-md-12 col-sm-12">
              <h2>{{resource.signUp}}</h2>
            </div>
            <div style="text-align: right;" class="col-lg-6 col-md-12 col-sm-12">
              {{resource.already_have_account}} ? <a style="font-weight: bold;"
                routerLink="/login">{{resource.signIn}}</a>
            </div>
          </div>
          <form [formGroup]="registerForm" (ngSubmit)="onSubmitRegister()" autocomplete="off">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <!-- <label>{{resource.first_name}} </label> -->
                  <mat-form-field>
                    <mat-label>{{resource.firstName}}</mat-label>
                    <input matInput [placeholder]="resource.firstName" formControlName="firstName" type="text" maxlength="50">
                    <mat-error *ngIf="submittedRegister && fReg.firstName.errors?.required" class="invalid-feedback">
                      {{resource.firstName}} {{resource.is_required}}
                    </mat-error>
                    <mat-error *ngIf="fReg.firstName.errors?.required && fReg.firstName.touched" class="invalid-feedback">*{{resource.firstName}} cannot be kept as blank.</mat-error>
                  </mat-form-field>
                </div>
              </div>
              <!-- <div class="col-lg-6 col-md-12">
                <div class="form-group">
                   <label>{{resource.last_name}} </label>
                  <mat-form-field>
                    <mat-label> {{resource.last_name}}</mat-label>
                    <input matInput [placeholder]="resource.last_name" formControlName="lastName" type="text" maxlength="50">
                    <mat-error *ngIf="submittedRegister && fReg.lastName.errors?.required" class="invalid-feedback">
                      {{resource.last_name}} {{resource.is_required}}
                    </mat-error>
                  </mat-form-field>
                </div>
              </div> -->
            </div>
            <!-- <div class="row">
              <div class="col-lg-4 col-md-12">
                <div class="form-group">
                   <label>{{resource.country}} </label> 
                  <mat-form-field>
                    <mat-label> {{resource.country}}</mat-label>
                    <mat-select [placeholder]="resource.country" formControlName="country" (selectionChange)="onChangeCountry($event)">
                      <mat-option>{{resource.country}}</mat-option>
                      <mat-option *ngFor="let country of countryList" [value]="country.id">
                        {{country.name}}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="submittedRegister && fReg.country.errors?.required" class="invalid-feedback">
                      {{resource.country}} {{resource.is_required}}
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="col-lg-4 col-md-4">
                <div class="form-group">
                   <label>{{resource.state}} </label> 
                  <mat-form-field>
                    <mat-label> {{resource.state}}</mat-label>
                    <mat-select [placeholder]="resource.state" formControlName="stateId" (selectionChange)="onChangeState($event)">
                      <mat-option>{{resource.state}}</mat-option>
                      <mat-option *ngFor="let state of stateList" [value]="state.id">
                        {{state.name}}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="submittedRegister && fReg.stateId.errors?.required" class="invalid-feedback">
                      {{resource.state}} {{resource.is_required}}
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="col-lg-4 col-md-4">
                <div class="form-group">
                  <label>{{resource.city}}</label> 
                  <mat-form-field>
                    <mat-label> {{resource.city}}</mat-label>
                    <mat-select [placeholder]="resource.city" formControlName="cityId">
                      <mat-option>{{resource.city}}</mat-option>
                      <mat-option *ngFor="let city of cityList" [value]="city.id">
                        {{city.name}}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="submittedRegister && fReg.cityId.errors?.required" class="invalid-feedback">
                      {{resource.city}} {{resource.is_required}}
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div> -->

            <div class="row">
              <!-- <div class="col-lg-2 col-md-6">
                <div class="form-group">
                  <label>Country Code</label>
                  <mat-form-field>
                    <input matInput formControlName="countryCode" type="text" readonly="">
                  </mat-form-field>
                </div>
              </div> -->
              <div class="col-md-12">
                <div class="form-group">
                  <!-- <label>{{resource.email}}</label> -->
                  <mat-form-field>
                    <mat-label> {{resource.email}}</mat-label>
                    <input matInput [placeholder]="resource.email" formControlName="email" oninput="this.value = this.value.toLowerCase()" type="email"
                      maxlength="30">
                    <mat-error *ngIf="submittedRegister && fReg.email.errors?.required" class="invalid-feedback">
                      {{resource.email}} {{resource.is_required}}
                    </mat-error>
                    <mat-error
                      *ngIf="submittedRegister  && fReg.email.errors?.pattern && (registerForm.touched || registerForm.dirty)"
                      class="invalid-feedback">
                      {{resource.invalid}} {{resource.email}}
                    </mat-error>
                    <mat-error *ngIf="fReg.email.errors?.required && fReg.email.touched" class="invalid-feedback">*{{resource.email}} cannot be kept as blank.</mat-error>
                  </mat-form-field>
                </div>
              </div>
              <!-- <div class="col-lg-6 col-md-6">
                <div class="form-group">
                   <label>{{resource.telephone}}</label> -->
                  <!-- <mat-form-field>
                    <mat-label> {{resource.telephone}}</mat-label>
                    <span style="margin-left: 5px;" matPrefix>+{{countryCode}} &nbsp;</span>
                    <input matInput [placeholder]="resource.telephone" formControlName="telephone" OnlyNumber type="tel" maxlength="15">
                    <mat-error *ngIf="submittedRegister && fReg.telephone.errors?.required" class="invalid-feedback">
                      {{resource.telephone}} {{resource.is_required}}
                    </mat-error>
                    <mat-error class="invalid-feedback" *ngIf="submittedRegister && (fReg.telephone.errors?.minlength)">
                      {{resource.telephone_must_10_digit}}
                    </mat-error>
                  </mat-form-field> 
                </div>
              </div> -->
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <!-- <label>{{resource.password}}</label> -->
                  <mat-form-field>
                    <mat-label> {{resource.password}}</mat-label>
                    <input matInput [placeholder]="resource.password" formControlName="password" type="password" maxlength="20">
                    <i class="bx bx-info-circle tooltip-icon" ></i>
                    <span class="tooltip-text">
                      <ol><span class="passinfoheading">{{resource.passwordRequirement}}</span>
                        <li>{{resource.minlengthInfo}}</li>
                        <li>{{resource.passwordInfo}}</li>
                      </ol>
                    </span>
                    <mat-error *ngIf="submittedRegister && fReg.password.errors?.required" class="invalid-feedback">
                      {{resource.password}} {{resource.is_required}}
                    </mat-error>
                    <mat-error
                      *ngIf="submittedRegister  && fReg.password.errors?.pattern && (registerForm.touched || registerForm.dirty)"
                      class="invalid-feedback">
                      {{resource.invalid}} {{resource.password}}
                    </mat-error>
                    <mat-error *ngIf="fReg.password.errors?.required && fReg.password.touched" class="invalid-feedback">*{{resource.password}} cannot be kept as blank.</mat-error>
                  </mat-form-field>
                </div>
              </div>
              <!-- <div class="col-lg-6 col-md-12">
                <div class="form-group">
                  <label>{{resource.confirm_password}} </label>
                  <mat-form-field>
                    <mat-label> {{resource.confirm_password}}</mat-label>
                    <input matInput [placeholder]="resource.confirm_password" formControlName="confirmPassword" type="password" maxlength="10">
                    <mat-error *ngIf="submittedRegister && fReg.confirmPassword.errors?.required"
                      class="invalid-feedback">
                      {{resource.confirm_password}} {{resource.is_required}}
                    </mat-error>
                    <mat-error class="invalid-feedback"
                      *ngIf="(fReg.confirmPassword.errors?.mustMatch && (registerForm.touched || registerForm.dirty))">
                      {{resource.password_not_match}}
                    </mat-error>
                  </mat-form-field>
                </div>
              </div> -->
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <!-- <label>{{resource.confirm_password}} </label> -->
                  <mat-form-field>
                    <mat-label> {{resource.confirm_password}}</mat-label>
                    <input matInput [placeholder]="resource.confirm_password" formControlName="confirmPassword" type="password" maxlength="20">
                    <mat-error *ngIf="submittedRegister && fReg.confirmPassword.errors?.required"
                      class="invalid-feedback">
                      {{resource.confirm_password}} {{resource.is_required}}
                    </mat-error>
                    <mat-error class="invalid-feedback"
                      *ngIf="(fReg.confirmPassword.errors?.mustMatch && (registerForm.touched || registerForm.dirty))">
                      {{resource.password_not_match}}
                    </mat-error>
                    <!-- <mat-error *ngIf="fReg.confirmPassword.errors?.required && fReg.confirmPassword.touched" class="invalid-feedback">*{{resource.confirm_password}} cannot be kept as blank.</mat-error> -->
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <mat-checkbox formControlName="termsCondition"
                    (change)="onClickTermCondition()">{{resource.term_conditions}}
                  </mat-checkbox>
                </div>
              </div>
            </div>
            <div class="col-lg-6 offset-lg-3 col-md-12">
              <button [disabled]="!fReg.termsCondition.value" type="submit">{{resource.signUp}} </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>