<div class="top-header-area">
    <div class="container-fluid {{containerClass}}">
        <div class="row align-items-center">
            <div class="col-lg-2 col-md-2 col-5">
                <ul class="top-header-contact-info">
                    <!-- <li><a href="tel:+44458895456"><i class='bx bx-phone-call'></i> +44 458 895 456</a></li>
                    <li><a href="mailto:hello@marxa.com"><i class='bx bx-envelope'></i> hello@marxa.com</a></li> -->
                    <li>
                        <i class='bx bx-globe'></i>
                        <!-- <span (click)="onClickCountryLabel()">{{selectedLanguage.text}} <i
                                class='bx bx-chevron-down'></i></span> -->
                        <select class="col-lg-6 col-8 navbar-country-dropdown" [(ngModel)]="selectedCountry" (change)="onChangeCountry()">
                            <!-- <option value="">ALL</option> -->
                            <option *ngFor="let country of countryList" [value]="country.id">
                                {{country.name}}
                            </option>
                        </select>
                    </li>
                </ul>
            </div>
            <div class="col-lg-10 col-md-10 col-7">
                <div class="top-header-right">
                    <ul class="d-flex align-items-center justify-content-end">
                        <!-- <span style="color:white; padding-right: 15px;"><i class='bx bx-share-alt'></i></span>
                        <span style="color:white; padding-right: 15px"><i class='bx bx-bell'></i></span> -->
                        <span></span>

                        <li *ngIf="!isLoggedIn"><a routerLink="/login"><i class='bx bx-user'></i>
                                {{resource.signIn}}</a></li>
                        <!-- <li *ngIf="!isLoggedIn"><a routerLink="/login"><i class='bx bx-log-in'></i> {{resource.signIn}}</a></li>
                        <li *ngIf="!isLoggedIn"><a routerLink="/register"><i class='bx bx-user'></i> {{resource.signUp}}</a></li> -->
                        <!-- <li *ngIf="isLoggedIn">{{resource.hello}}, {{loginUserName}}</li> -->
                        <li *ngIf="isLoggedIn"><a href="javascript:void(0)" (click)="logout()"><i
                                    class='bx bx-log-out'></i>
                                {{resource.logout}}</a></li>
                        <li>
                            <select class="col-lg-12 col-12 navbar-language-dropdown" [(ngModel)]="selectedLanguage" (change)="onChangeLanguage($event)">
                                <option *ngFor="let lan of languages" [value]="lan.value">
                                    <img [src]="lan.src" class="shadow-sm" alt="flag">
                                    <span>{{lan.text}}</span>
                                </option>
                            </select>
                        </li>
                        <!-- <li>
                            <div class="dropdown language-switcher d-inline-block" [class.active]="classApplied">
                                <button class="dropdown-toggle" type="button" (click)="toggleClass()">
                                    <img [src]="selectedLanguage.src" class="shadow" alt="image">
                                    <span>{{selectedLanguage.text}} <i class='bx bx-chevron-down'></i></span>
                                </button>
                                <div class="dropdown-menu">
                                    <a href="javascript:void(0)" *ngFor="let lan of languages;"
                                        class="dropdown-item d-flex align-items-center" (click)="onClickLanguage(lan)">
                                        <img [src]="lan.src" class="shadow-sm" alt="flag">
                                        <span>{{lan.text}}</span>
                                    </a>
                                </div>
                            </div>
                        </li> -->
                    </ul>
                    <!-- <ul class="d-flex align-items-center justify-content-end">
                        <li>
                            <div class="dropdown language-switcher d-inline-block" [class.active]="classApplied">
                                <button class="dropdown-toggle" type="button" (click)="toggleClass()">
                                    <img src="assets/img/flag/us.jpg" class="shadow" alt="image">
                                    <span>Eng <i class='bx bx-chevron-down'></i></span>
                                </button>
                                <div class="dropdown-menu">
                                    <a href="#" class="dropdown-item d-flex align-items-center">
                                        <img src="assets/img/flag/germany.jpg" class="shadow-sm" alt="flag">
                                        <span>Ger</span>
                                    </a>
                                    <a href="#" class="dropdown-item d-flex align-items-center">
                                        <img src="assets/img/flag/france.jpg" class="shadow-sm" alt="flag">
                                        <span>Fre</span>
                                    </a>
                                    <a href="#" class="dropdown-item d-flex align-items-center">
                                        <img src="assets/img/flag/spain.jpg" class="shadow-sm" alt="flag">
                                        <span>Spa</span>
                                    </a>
                                    <a href="#" class="dropdown-item d-flex align-items-center">
                                        <img src="assets/img/flag/russia.jpg" class="shadow-sm" alt="flag">
                                        <span>Rus</span>
                                    </a>
                                    <a href="#" class="dropdown-item d-flex align-items-center">
                                        <img src="assets/img/flag/italy.jpg" class="shadow-sm" alt="flag">
                                        <span>Ita</span>
                                    </a>
                                </div>
                            </div>
                        </li>
                        <li><a routerLink="/profile-authentication"><i class='bx bx-log-in'></i> Sign In</a></li>
                        <li><a routerLink="/profile-authentication"><i class='bx bx-user'></i> Sign Up</a></li>
                    </ul> -->
                </div>
            </div>
        </div>
    </div>
</div>