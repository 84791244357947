import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from 'src/app/services/common/alert.service';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  queryString = Object();

  submitted = false;
  resetForm: FormGroup;

  get f() { return this.resetForm.controls; }

  constructor(private loginService: LoginService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.queryString = this.activatedRoute.snapshot.queryParams;
    this.buildForm();

    if (this.queryString?.token) {
      var token = this.queryString?.token;
      var userId = window.atob(token);
      this.f.userId.setValue(userId);
    }
    else {
      this.alertService.showError("Invalid link");
      this.router.navigate(['login']);
    }
  }

  buildForm(): void {
    this.resetForm = this.formBuilder.group({
      userId: ['', [Validators.required]],
      password: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]],
    }, {
      validators: this.passwordMatch.bind(this)
    }
    );
  }

  passwordMatch(formGroup: FormGroup) {
    const password = formGroup.get('password');
    const confirmPassword = formGroup.get('confirmPassword');

    const matchingControl = formGroup.controls['confirmPassword'];

    // return if another validator has already found an error on the matchingControl
    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      return;
    }

    // set error on matchingControl if validation fails
    if (password?.value === confirmPassword?.value) {
      matchingControl.setErrors(null);
    }
    else {
      matchingControl.setErrors({ mustMatch: true });
    }
  }

  public onSubmit() {
    this.submitted = true;
    if (this.resetForm.invalid)
      return;

    var data: any = {
      "userId": this.f.userId.value,
      "password": this.f.password.value
    }

    this.loginService.resetPassword(data).subscribe((res: any) => {
      if (res.success) {
        this.router.navigate(['/login']);
      }
    })
  }

  onClickBack() {
    this.router.navigate(['/login']);
  }
}