import { Component, OnInit } from '@angular/core';
import { ResourceService } from 'src/app/services/common/resource.service';

@Component({
    selector: 'app-term-condition-page',
    templateUrl: './term-condition-page.component.html',
    styleUrls: ['./term-condition-page.component.scss']
})
export class TermConditionPageComponent implements OnInit {
    resource: any = {};
    constructor(private resourceService: ResourceService) { }

    ngOnInit(): void {
        this.loadResoruce();
        this.resourceService.languageSubject.subscribe(response => {
            this.resource = response;
        });
    }

    loadResoruce() {
        this.resourceService.loadResoruce().subscribe(
            response => {
                this.resource = response;
            });
    }

}