<div class="page-title-area" style="background-image: url(assets/img/page-title/page-title5.jpg);">
    <div class="container">
        <h1>{{resource.term_conditions}}</h1>
    </div>
</div>

<div class="customer-service-area ptb-100">
    <div class="container">
        <div class="customer-service-content">
            <b>{{resource.term_conditions_heading1_1}} <u><a href="www.scanfluence.com"
                        target="_blank">www.scanfluence.com</a></u> {{resource.term_conditions_heading1_2}}</b>

            <h3>{{resource.term_conditions_user_agreement}}</h3>
            <p>{{resource.term_conditions_user_agreement_detail}}</p>

            <h3>{{resource.term_conditions_intellectual_property}}</h3>
            <p>{{resource.term_conditions_intellectual_property_detail}}</p>

            <h3>{{resource.term_conditions_age_restrictions}}</h3>
            <p>{{resource.term_conditions_age_restrictions_detail}}</p>

            <h3>{{resource.term_conditions_acceptable_use}}</h3>
            <p>{{resource.term_conditions_acceptable_use_detail_1}}</p>

            <ul>
                <li>{{resource.term_conditions_acceptable_use_1}}</li>
                <li>{{resource.term_conditions_acceptable_use_2}}</li>
                <li>{{resource.term_conditions_acceptable_use_3}}</li>
                <li>{{resource.term_conditions_acceptable_use_4}}</li>
                <li>{{resource.term_conditions_acceptable_use_5}}</li>
                <li>{{resource.term_conditions_acceptable_use_6}}</li>
                <li>{{resource.term_conditions_acceptable_use_7}}</li>
            </ul>

            <p>{{resource.term_conditions_acceptable_use_detail_2}}</p>


            <h3>{{resource.term_conditions_user_contributions}}</h3>
            <p>{{resource.term_conditions_user_contributions_detail}}</p>

            <h3>{{resource.term_conditions_accounts}}</h3>
            <p>{{resource.term_conditions_accounts_detail_1}}</p>

            <p><strong>1.</strong> {{resource.term_conditions_accounts_1}}<br>
                <strong>2.</strong> {{resource.term_conditions_accounts_2}}<br>
            </p>

            <p>{{resource.term_conditions_accounts_detail_2}}</p>


            <h3>{{resource.term_conditions_sale_of_goods}}</h3>

            <ul>
                <li>{{resource.term_conditions_sale_of_goods_1}}</li>
                <li>{{resource.term_conditions_sale_of_goods_2}}</li>
                <li>{{resource.term_conditions_sale_of_goods_3}}</li>
                <li>{{resource.term_conditions_sale_of_goods_4}}</li>
                <li>{{resource.term_conditions_sale_of_goods_5}}</li>
                <li>{{resource.term_conditions_sale_of_goods_6}}</li>
                <li>{{resource.term_conditions_sale_of_goods_7}}</li>
                <li>{{resource.term_conditions_sale_of_goods_8}}</li>
            </ul>

            <p>{{resource.term_conditions_sale_of_goods_detail_1}}</p>

            <p>{{resource.term_conditions_sale_of_goods_detail_2}}</p>

            <h3>{{resource.term_conditions_third_party_goods}}</h3>
            <p>{{resource.term_conditions_third_party_goods_detail}}</p>

            <h3>{{resource.term_conditions_user_goods_services}}</h3>
            <p>{{resource.term_conditions_user_goods_services_detail}}</p>

            <h3>{{resource.term_conditions_subscriptions}}</h3>
            <p>{{resource.term_conditions_subscriptions_detail_1}}</p>

            <p>{{resource.term_conditions_subscriptions_detail_2}}</p>

            <h3>{{resource.term_conditions_payments}}</h3>
            <p>{{resource.term_conditions_payments_detail_1}}</p>

            <ul>
                <li>{{resource.term_conditions_1}}</li>
                <li>{{resource.term_conditions_2}}</li>
                <li>{{resource.term_conditions_3}}</li>
                <li>{{resource.term_conditions_4}}</li>
            </ul>

            <p>{{resource.term_conditions_payments_detail_2}}</p>

            <p>{{resource.term_conditions_payments_detail_3}}</p>


            <h3>{{resource.term_conditions_consumer_protection_law}}</h3>
            <p>{{resource.term_conditions_consumer_protection_law_detail}}</p>

            <h3>{{resource.term_conditions_links_to_other_websites}}</h3>
            <p>{{resource.term_conditions_links_to_other_websites_detail}}</p>

            <h3>{{resource.term_conditions_limitation_of_liability}}</h3>
            <p> {{resource.term_conditions_limitation_of_liability_detail}}</p>

            <h3>{{resource.term_conditions_indemnity}}</h3>
            <p>{{resource.term_conditions_indemnity_detail}}</p>

            <h3>{{resource.term_conditions_applicable_law}}</h3>
            <p>{{resource.term_conditions_applicable_law_detail}}</p>

            <h3>{{resource.term_conditions_dispute_resolution}}</h3>
            <p>{{resource.term_conditions_dispute_resolution_detail_1}}</p>

            <p>{{resource.term_conditions_dispute_resolution_detail_2}}</p>

            <h3>{{resource.term_conditions_severability}}</h3>
            <p>{{resource.term_conditions_severability_detail}}</p>

            <h3>{{resource.term_conditions_changes}}</h3>
            <p>{{resource.term_conditions_changes_detail}}<p>

            <h3>{{resource.term_conditions_contact_details}}</h3>
            <p>{{resource.term_conditions_contact_details_1}}</p>

            <p><u>compliance@scanfluence.com</u></p>
            <p>125 EAST AVENUE</p>
            <p>{{resource.term_conditions_contact_details_2}}</p>

            <br>
            <p>{{resource.term_conditions_contact_details_3}}</p>
        </div>
    </div>
</div>