<!-- <div class="page-title-area" *ngFor="let Content of pageTitle;" style="background-image: url({{Content.bgImage}});">
    <div class="container">
        <h1>{{Content.title}}</h1>
    </div>
</div> -->

<div class="profile-authentication-area ptb-30">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 offset-lg-3 col-md-12">
                <div class="login-form">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <h2>{{resource.forgot_password}}</h2>
                        </div>
                    </div>
                    <form [formGroup]="forgetPasswordForm" (ngSubmit)="onSubmit()" autocomplete="off">
                        <div class="form-group">
                            <!-- <label>{{resource.email}}</label> -->
                            <mat-form-field>
                                <mat-label> {{resource.email}}</mat-label>
                                <input [placeholder]="resource.email" matInput formControlName="email"
                                    oninput="this.value = this.value.toLowerCase()" type="email" maxlength="100">
                                <mat-error *ngIf="submittedLogin && f.email.errors?.required" class="invalid-feedback">
                                    {{resource.email}} {{resource.is_required}}
                                </mat-error>
                                <mat-error
                                    *ngIf="submittedLogin  && f.email.errors?.pattern && (forgetPasswordForm.touched || forgetPasswordForm.dirty)"
                                    class="invalid-feedback">
                                    {{resource.invalid}} {{resource.email}}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="row align-items-center">
                            <div class="col-lg-6 col-md-6 col-sm-6 remember-me-wrap">
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 lost-your-password-wrap">
                                <a routerLink="/login" class="lost-your-password">{{resource.back_to_signIn}}</a>
                            </div>
                        </div>
                        <button type="submit">{{resource.submit}}</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>