import { Injectable } from "@angular/core";
import { NotifierService, NotifierOptions } from "angular-notifier";

@Injectable({ providedIn: "root" })
export class AlertService {
    constructor(private notifier: NotifierService) { }

    showInfo(message: string): void {
        if (message != undefined && message != null)
            this.notifier.notify("info", message);
    }

    showSuccess(message: string): void {
        if (message != undefined && message != null)
            this.notifier.notify("success", message);
    }

    showWarning(message: string): void {
        if (message != undefined && message != null)
            this.notifier.notify("warning", message);
    }

    showError(message: string): void {
        if (message != undefined && message != null)
            this.notifier.notify("error", message);
    }

    showDefault(message: string): void {
        if (message != undefined && message != null)
            this.notifier.notify("default", message);
    }

    hideAll(): void {
        this.notifier.hideAll();
    }
}
