<div class="page-title-area" style="background-image: url(assets/img/page-title/page-title5.jpg);">
  <div class="container">
    <h1>{{resource.privacy_policy}}</h1>
  </div>
</div>

<div class="customer-service-area ptb-100">
  <div class="container">
    <div class="customer-service-content">
      <b><u><a href="www.scanfluence.com" target="_blank">www.scanfluence.com</a></u>
        {{resource.privacy_policy_heading}}</b>

      <h3>{{resource.privacy_policy_our_commitment}}</h3>
      <p>{{resource.privacy_policy_our_commitment_detail_1}}</p>

      <p>{{resource.privacy_policy_our_commitment_detail_2}} <u><a href="compliance@scanfluence.com"
            target="_blank">compliance@scanfluence.com</a></u></p>

      <h3>{{resource.privacy_policy_security_personal_information}}</h3>
      <p>{{resource.privacy_policy_security_personal_information_detail_1}}</p>
      <p>{{resource.privacy_policy_security_personal_information_detail_2}}</p>
      <p>{{resource.privacy_policy_security_personal_information_detail_3}}</p>

      <h3>{{resource.privacy_policy_how_we_use_personal_information}}</h3>
      <p>{{resource.privacy_policy_how_we_use_personal_information_detail_1}}</p>

      <p>{{resource.privacy_policy_how_we_use_personal_information_detail_2}}</p>

      <h3>{{resource.privacy_policy_share_your_information}}</h3>
      <p>{{resource.privacy_policy_share_your_information_detail_1}}</p>

      <p>{{resource.privacy_policy_share_your_information_detail_2}}</p>

      <p>{{resource.privacy_policy_share_your_information_detail_3}}</p>



      <h3>{{resource.privacy_policy_collection_of_your_personal_information}}</h3>
      <p>{{resource.privacy_policy_collection_of_your_personal_information_detail}}</p>

      <h3>{{resource.privacy_policy_privacy_of_information}}</h3>
      <p>{{resource.privacy_policy_privacy_of_information_detail}}</p>


      <h3>{{resource.privacy_policy_advertising}}</h3>
      <p>{{resource.privacy_policy_advertising_detail_1}}
        <a href="https://www.google.com/url?q=https://www.google.com/ads/preferences/&amp;source=gmail-html&amp;ust=1681464135990000&amp;usg=AOvVaw3JqSUyH_z-suHxRLbh7W_2"
          target="_blank" rel="noreferrer">Google's Opt-out</a>,
        <a href="https://www.google.com/url?q=https://advertise.bingads.microsoft.com/en-us/resources/policies/opt-out-of-the-bing-ads-optimization-program&amp;source=gmail-html&amp;ust=1681464135990000&amp;usg=AOvVaw3myC83NrwWsLJE-GQPkPck"
          target="_blank" rel="noreferrer">Bing's Opt-out,</a>
        or the
        <a href="https://optout.networkadvertising.org/?c=1">Network Advertising Initiative Opt-out page.</a>
      </p>

      <p>{{resource.privacy_policy_advertising_detail_2}}</p>

      <h3>{{resource.privacy_policy_third_party_services}}</h3>
      <p>{{resource.privacy_policy_third_party_services_1}} <a
          href="https://www.google.com/url?q=https://www.google.com/policies/privacy&amp;source=gmail-html&amp;ust=1681464135990000&amp;usg=AOvVaw2P5RVcvw4b26GeJgqHk06f"
          target="_blank" rel="noreferrer">Google's Privacy Policy</a>
        {{resource.privacy_policy_third_party_services_2}}</p>

      <h3>{{resource.privacy_policy_gdpr}}</h3>

      <p>{{resource.privacy_policy_gdpr_detail}}</p>

      <h3>{{resource.privacy_policy_consent}}</h3>
      <p>{{resource.privacy_policy_consent_detail}}</p>

      <p><strong>1.</strong> {{resource.privacy_policy_consent_1}}</p>

      <p>{{resource.privacy_policy_consent_2}}</p>

      <p>{{resource.privacy_policy_consent_3}} <u><a href="compliance@scanfluence.com"
            target="_blank">compliance@scanfluence.com</a></u> </p>

      <h3>{{resource.privacy_policy_your_rights_as_user}}</h3>

      <p>{{resource.privacy_policy_your_rights_as_user_detail}}</p>


      <p><strong>1.</strong> {{resource.privacy_policy_your_rights_as_user_1}}<br>
        <strong>2.</strong> {{resource.privacy_policy_your_rights_as_user_2}}<br>
        <strong>3.</strong> {{resource.privacy_policy_your_rights_as_user_3}}<br>
        <strong>4.</strong> {{resource.privacy_policy_your_rights_as_user_4}}<br>
        <strong>5.</strong> {{resource.privacy_policy_your_rights_as_user_5}}<br>
        <strong>6.</strong> {{resource.privacy_policy_your_rights_as_user_6}}<br>
        <strong>7.</strong> {{resource.privacy_policy_your_rights_as_user_7}}
      </p>

      <h3>{{resource.privacy_policy_children}}</h3>
      <p>{{resource.privacy_policy_children_detail}}</p>

      <h3>{{resource.privacy_policy_how_to_opt_out}}</h3>
      <p>{{resource.privacy_policy_how_to_opt_out_detail}}</p>

      <p><strong>1.</strong> {{resource.privacy_policy_how_to_opt_out_1}}<br>
      </p>


      <h3>{{resource.privacy_policy_cookie_policy}}</h3>
      <p>{{resource.privacy_policy_cookie_policy_detail_1}}</p>

      <p>{{resource.privacy_policy_cookie_policy_detail_2}}</p>


      <ul>
        <li>{{resource.privacy_policy_cookie_policy_1}}</li>
        <p>{{resource.privacy_policy_cookie_policy_1_1}}</p>

        <li>{{resource.privacy_policy_cookie_policy_2}}</li>
        <p>{{resource.privacy_policy_cookie_policy_2_1}}</p>

        <li>{{resource.privacy_policy_cookie_policy_3}}</li>
        <p>{{resource.privacy_policy_cookie_policy_3_1}}</p>

        <li>{{resource.privacy_policy_cookie_policy_4}}</li>
        <p>{{resource.privacy_policy_cookie_policy_4_1}}</p>
        <p> a. {{resource.privacy_policy_cookie_policy_4_1_a}}</p>
      </ul>

      <h3>{{resource.privacy_policy_modifications}}</h3>
      <p>{{resource.privacy_policy_modifications_detail}}</p>

      <h3>{{resource.privacy_policy_complaints}}</h3>
      <p>{{resource.privacy_policy_complaints_detail}}</p>

      <h3>{{resource.privacy_policy_contact_information}}</h3>
      <p>{{resource.privacy_policy_contact_information_detail}} <u><a href="compliance@scanfluence.com"
            target="_blank">compliance@scanfluence.com</a></u></p>

    </div>
  </div>
</div>