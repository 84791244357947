import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ResourceService } from 'src/app/services/common/resource.service';
import { StorageKey, StorageService } from 'src/app/services/common/storage.service';
import { UserInfoService } from 'src/app/services/user-info.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  isLoggedIn: any = false;
  resource: any = {};
  isOrganizationURL: boolean = false;
  isVisbileTagLink: boolean = true;

  constructor(private storageService: StorageService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private _userInfoService: UserInfoService,
    private _storageService: StorageService,
    private resourceService: ResourceService) {
    router.events.subscribe((val) => {
      this.storageService.navbarSubject.next(true);
    });
  }

  ngOnInit(): void {
    this.loadResoruce();
    this.resourceService.languageSubject.subscribe(response => {
      this.resource = response;
    });

    this.router.events
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          let location = this.router.url;
          if (location == '/') {
            this.isVisbileTagLink = false;
          } else {
            this.isVisbileTagLink = true;
          }
        }
      });

    if (window.location.href.includes("?")) {
      const queryStringParams = window.location.href
        .split('?')[1]
        .split('&')
        .map(keyValue => keyValue.split('='))
        .reduce<any>((params, [key, value]) => {
          params[key] = value;
          return params;
        }, {});

      if (queryStringParams?.organization) {
        this.isOrganizationURL = true;
      }
      else {
        this.isOrganizationURL = false;
      }
    } else { this.isOrganizationURL = false; }

    this.storageService.navbarSubject.subscribe(response => {
      var a = this.router;
      this.isOrganizationURL = false;
      if (window.location.href.includes("?")) {
        const queryStringParams = window.location.href
          .split('?')[1]
          .split('&')
          .map(keyValue => keyValue.split('='))
          .reduce<any>((params, [key, value]) => {
            params[key] = value;
            return params;
          }, {});

        if (queryStringParams?.organization) {
          this.isOrganizationURL = true;
        }
      }
    });

    this.isLoggedIn = this._userInfoService.getLoginFlag();
    this.storageService.loginSubject.subscribe(message => {
      this.isLoggedIn = this._userInfoService.getLoginFlag();
    });
  }

  loadResoruce() {
    this.resourceService.loadResoruce().subscribe(
      response => {
        this.resource = response;
      });
  }

  classApplied = false;
  toggleClass() {
    this.classApplied = !this.classApplied;
  }

  logout() {
    this.toggleClass();
    this._userInfoService.setToken();
    this._storageService.removeValue(StorageKey.authToken);
    this._storageService.removeValue(StorageKey.currentUser);
    this._storageService.removeValue(StorageKey.rememberMe);
    //sessionStorage.setItem('currentUser', JSON.stringify({}));
    sessionStorage.removeItem('currentUser');
    this._userInfoService.setLoginFlag(false);
    this._storageService.loginSubject.next(false);
    //this.cookieService.delete('currentUser');
    this.router.navigate(['/']);
  }
}