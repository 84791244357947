import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/services/common/alert.service';
import { ResourceService } from 'src/app/services/common/resource.service';
import { StorageKey, StorageService } from 'src/app/services/common/storage.service';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit {

  resource: any = {};
  constructor(
    private router: Router,
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    private loginService: LoginService,
    private storageService: StorageService,
    private resourceService: ResourceService
  ) { }

  submittedLogin = false;
  forgetPasswordForm: FormGroup;
  get f() { return this.forgetPasswordForm.controls; }

  ngOnInit(): void {
    this.loadResoruce();
    this.resourceService.languageSubject.subscribe(response => {
      this.resource = response;
    });
    this.buildForm();
  }

  loadResoruce() {
    this.resourceService.loadResoruce().subscribe(
      response => {
        this.resource = response;
      });
  }

  buildForm(): void {
    this.forgetPasswordForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]]
    });

  }

  onSubmit() {
    this.submittedLogin = true;

    if (this.forgetPasswordForm.invalid)
      return;

    var logininfo: any = {
      "email": this.f.email.value.trim(),
    }

    this.loginService.forgetPassword(logininfo).subscribe((res: any) => {
      if (res.success) {
        this.alertService.showSuccess("Your reset password link sent successfully");
        this.router.navigate(['login']);
      }
      else {
        this.alertService.showError(res.message);
      }
    },
      (err: any) => {
        this.storageService.loginSubject.next(false);
        this.alertService.showError(err.error.error.message);
      }
    )
  }
}